import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import {Link} from "react-router-dom";

const Forms = () => {
    return (
        <>
            <SEO title="Form Download" />

            <Layout>
            <div className="main-content">
                {/* Start Service Area  */}
                <div className="rwt-contact-area rn-section-gap">
                    <div className="container">
                        <h4><p>&nbsp;</p> </h4>
                        <h4><p>&nbsp;</p> </h4>
                        <h4>表格下載</h4>
                        <div className="row">
                            <ul >
                                <li><a href="./docs/DormantAccountReactivationForm(Individual).pdf" target="_blank"> 重新啟動不動戶表格(個人/聯名賬戶) </a></li>
                                <li><a href="./docs/DormantAccountReactivationForm(Corporate).pdf" target="_blank"> 重新啟動不動戶表格(公司賬戶) </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}
            </div>

            </Layout>

        </>
    )
}

export default Forms;
